<script setup lang="ts">
import store from "@/store";
import { ref, onMounted, computed, watchEffect, watch } from "vue";
// import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { getDate, getHour, getWeek } from "@/common/utils/dates";
import { toast } from "@/common/utils/toast";

import { BaseConfirm, BaseModal } from "@/components";

import { concurseList, concurseJurors } from "@/common/api/rest/concurses";
import {
  candidateList,
  candidateAdd,
  candidateDel,
} from "@/common/api/rest/candidates";

import type { ConcurseType } from "@/common/api/rest/concurses";
import type {
  CandidatePayloadType,
  CandidateType,
} from "@/common/api/rest/candidates";

// const { t, tm } = useI18n();

const router = useRouter();

const concurses = ref<ConcurseType[]>([]);
const concursesList = ref<string[]>([]);
const jurorsList = ref<string[]>([]);

const candidate = ref<CandidateType[]>([]);

const concurseId = ref();
const concurse = ref<CandidateType>();

const showTerms = ref(false);
const modalQuit = ref(false);

const quitMessage = computed(
  () =>
    `Deseja desistir do concurso <strong>"${concurse.value?.concurse.name}"</strong>`
);

const jurors = computed(() => store.getters.profile?.jurors);

function addCandidate() {
  const { id } = store.getters.user;
  const { first_name, last_name, social_name } = store.getters.profile;

  const payload: CandidatePayloadType = {
    concurse_id: concurseId.value,
    member_id: id,
    name: social_name ?? `${first_name} ${last_name}`,
    personage: "",
    universe: "",
    url_mp3: "",
    url_video: "",
    description: "",
    terms_accept: true,
  };

  showTerms.value = false;
  store.commit("SET_LOADING", true);

  candidateAdd(payload)
    .then((response) => {
      const { success, data } = response;

      if (success) {
        fetchConcurses();
        fetchCandidates();
        concurseId.value = "";
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: "Erro interno do servidor", type: "error" });
    })
    .finally(() => {
      store.commit("SET_LOADING", false);
      concurseId.value = "";
    });
}

function delCandidate() {
  store.commit("SET_LOADING", true);
  modalQuit.value = false;

  candidateDel(concurse.value.candidate_id)
    .then((response) => {
      const { success, data } = response;

      if (success) {
        fetchConcurses();
        fetchCandidates();
        concurseId.value = "";
        concurse.value = undefined;
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: "Erro interno do servidor", type: "error" });
    })
    .finally(() => {
      store.commit("SET_LOADING", false);
      concurseId.value = "";
      concurse.value = undefined;
    });
}

function quitConcurse(data: CandidateType) {
  concurse.value = data;
  modalQuit.value = true;
}

function enterConcurse(concurse_id: string) {
  concurseId.value = concurse_id;
  showTerms.value = true;
}

function goToConcurse(candidate_id: string) {
  router.push(`concurses/${candidate_id}`);
}

function evaluateConcurse(concurse_id: string) {
  router.push(`concurses/evaluate/${concurse_id}`);
}

function fetchConcurseJurors() {
  concurseJurors(store.getters.user.id as string)
    .then((response) => {
      const { success, data } = response;

      if (success) {
        jurorsList.value = [...data].map((item) => item.concurse_id);
      }
    })
    .finally(() => {
      store.commit("SET_LOADING", false);
    });
}

function fetchConcurses() {
  concurseList()
    .then((response) => {
      const { success, data } = response;

      if (success) {
        concurses.value = data;
      }
    })
    .finally(() => {
      store.commit("SET_LOADING", false);
    });
}

function fetchCandidates() {
  candidateList(store.getters.user.id)
    .then((response) => {
      const { success, data } = response;

      if (success) {
        candidate.value = [];
        concursesList.value = [];

        data.forEach((item) => {
          candidate.value.push(item);
          concursesList.value.push(item.concurse_id);
        });
      }
    })
    .finally(() => {
      store.commit("SET_LOADING", false);
    });
}

function checkDisabled(data: ConcurseType) {
  return (
    concursesList.value.includes(data.concurse_id) ||
    data.used === data.available ||
    data.status === "finished" ||
    jurors.value
  );
}

onMounted(() => {
  store.commit("SET_LOADING", true);
  fetchConcurses();
  fetchCandidates();
  fetchConcurseJurors();
});

watch(
  () => jurors.value,
  () => {
    fetchConcurseJurors();
  }
);
</script>

<template>
  <section class="concurses-page">
    <h2 class="uppercase">CONCURSOS</h2>
    <div class="concurses">
      <template v-for="item in concurses" :key="item.id">
        <div class="concurse">
          <div class="infos">
            <div class="title">
              {{ item.name }}
            </div>
            <div class="date">
              <span>
                <span class="uppercase">
                  <strong>
                    {{ getWeek(item.event_date) }}
                  </strong>
                </span>
                <span> | </span>
                <span>
                  {{ getDate(item.event_date) }}
                </span>
                <span> - </span>
                <span>
                  {{ getHour(item.event_date) }}
                </span>
              </span>
            </div>
            <div class="resume" v-html="item.description" />
          </div>
          <div class="actions">
            <div class="vacance">
              <div class="value">
                {{ item.available - item.used }}
              </div>
              <div class="label">
                <span>VAGAS</span>
                <span>DISPONÍVEIS</span>
              </div>
            </div>
            <div v-if="!jurorsList.includes(item.concurse_id)" class="register">
              <template v-if="checkDisabled(item)">
                <button disabled>CONCORRER</button>
              </template>
              <template v-else>
                <button @click="enterConcurse(item.concurse_id)">
                  CONCORRER
                </button>
              </template>
            </div>
          </div>
          <div v-if="jurorsList.includes(item.concurse_id)" class="jurors">
            <template v-if="item.status === 'finished'">
              <button @click="evaluateConcurse(item.concurse_id)">
                CONCURSO AVALIADO
              </button>
            </template>
            <template v-else-if="!jurorsList.includes(item.concurse_id)">
              <button disabled>AVALIAR CONCURSO</button>
            </template>
            <template v-else>
              <button @click="evaluateConcurse(item.concurse_id)">
                AVALIAR CONCURSO
              </button>
            </template>
          </div>
        </div>
      </template>
      <template v-if="concurses.length === 0">
        <div class="concurse">Não existem concursos ativos.</div>
      </template>
    </div>

    <h2 class="uppercase">MEUS CONCURSOS</h2>
    <div class="candidates">
      <template v-for="item in candidate" :key="item.id">
        <div class="candidate">
          <div class="number">
            <div class="label">CANDIDATO</div>
            <div class="value">
              {{ item.candidate_number }}
            </div>
          </div>
          <div class="infos">
            <div class="title">
              {{ item.concurse.name }}
            </div>
            <div class="date">
              <span>
                <span class="uppercase">
                  <strong>
                    {{ getWeek(item.concurse.event_date) }}
                  </strong>
                </span>
                <span> | </span>
                <span>
                  {{ getDate(item.concurse.event_date) }}
                </span>
                <span> - </span>
                <span>
                  {{ getHour(item.concurse.event_date) }}
                </span>
              </span>
            </div>
          </div>
          <div class="actions">
            <button class="edit" @click="goToConcurse(item.candidate_id)">
              ACESSAR
            </button>
            <button class="quit" @click="quitConcurse(item)">DESISTIR</button>
          </div>
        </div>
      </template>
      <template v-if="candidate.length === 0">
        <div class="candidate">Você não possui concursos.</div>
      </template>
    </div>

    <div v-if="showTerms" class="terms">
      <div class="box">
        <iframe
          src="https://drive.google.com/file/d/1u5dq3ACD6gix3Gku4RL8psGLhJ-woXp_/preview"
          allow="autoplay"
        />
        <div class="actions">
          <button class="accept" @click="addCandidate()">ACEITO</button>
          <button class="refuse" @click="showTerms = false">NÃO ACEITO</button>
        </div>
      </div>
    </div>

    <base-confirm
      :show="modalQuit"
      title="DESISTIR DO CONCURSO"
      :message="quitMessage"
      @confirm="delCandidate()"
      @cancel="modalQuit = false"
    />
  </section>
</template>

<style lang="scss" scoped>
.concurses-page {
  display: flex;
  min-height: 63vh;
  flex-direction: column;
  padding-block: 2rem;

  .concurses {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    margin-block-start: 1rem;
    margin-block-end: 3rem;

    .concurse {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 20px;
      padding-block: 25px;
      padding-inline: 30px;
      background: white;
      border: 2px solid black;
      border-radius: 10px;

      .infos {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .title {
          font-size: 20px;
          font-weight: bold;
          text-transform: uppercase;
        }

        .resume {
          word-break: normal;
          white-space: pre-line;
          font-size: 0.9rem;
        }
      }

      .actions {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        margin-block-start: auto;

        .vacance {
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;

          .label {
            display: flex;
            flex-direction: column;
            font-size: 0.75rem;
            font-weight: bold;
          }

          .value {
            font-size: 3rem;
            font-weight: bold;
          }
        }

        .register {
          flex: 1;
          display: flex;
          justify-content: flex-end;

          button {
            cursor: pointer;
            width: 140px;
            height: 45px;
            background: var(--primary);
            color: black;
            outline: none;
            border: 0;
            font-size: 1rem;
            font-weight: 600;
            border: 1px solid white;
            border-radius: 8px;

            &:hover {
              background: var(--primary-hover);
              border: 1px solid black;
            }

            &:active {
              background: var(--primary-active);
            }

            &:disabled {
              cursor: default;
              background: #e8e8e8;
              border: 0;
            }
          }
        }
      }

      .jurors {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 15px;
        border-block-start: 1px solid black;
        padding-block-start: 20px;
        align-items: center;

        button {
          cursor: pointer;
          width: 250px;
          height: 45px;
          background: var(--primary);
          color: black;
          outline: none;
          border: 0;
          font-size: 1rem;
          font-weight: 600;
          border: 1px solid white;
          border-radius: 8px;

          &:hover {
            background: var(--primary-hover);
            border: 1px solid black;
          }

          &:active {
            background: var(--primary-active);
          }

          &:disabled {
            cursor: default;
            background: #e8e8e8;
            border: 0;
          }
        }
      }
    }
  }

  .candidates {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-block-start: 1rem;
    margin-block-end: 3rem;

    .candidate {
      flex: 1;
      display: flex;
      flex-direction: row;
      gap: 20px;
      padding-block: 25px;
      padding-inline: 30px;
      background: white;
      border: 2px solid black;
      border-radius: 10px;

      .number {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: fit-content;
        height: fit-content;
        gap: 5px;
        background: var(--primary);
        padding-inline: 10px;
        padding-block: 10px;
        border-radius: 8px;

        .label {
          font-size: 10px;
          line-height: 10px;
          font-weight: bold;
        }

        .value {
          font-size: 42px;
          line-height: 42px;
          font-weight: bold;
        }
      }

      .infos {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 10px;

        .title {
          font-size: 20px;
          font-weight: bold;
          text-transform: uppercase;
        }

        .resume {
          word-break: normal;
          white-space: pre-line;
          font-size: 0.9rem;
        }
      }

      .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: auto;
        gap: 20px;

        button {
          cursor: pointer;
          height: 50px;
          border: 1px solid transparent;
          padding-inline: 15px;
          background: #181818;
          color: #fff;
          font-weight: 600;
          border-radius: 8px;

          &:hover {
            background: black;
          }

          &:disabled {
            cursor: default;
            background: #e8e8e8;
            border: 0;
          }
        }

        .edit {
          background: var(--primary);
          color: black;

          &:hover {
            background: var(--primary-hover);
            border: 1px solid black;
          }

          &:active {
            background: var(--primary-active);
          }
        }
      }
    }
  }

  .terms {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #0000009f;

    .box {
      width: 800px;
      height: 550px;
      margin-inline: auto;
      background: white;
      border-radius: 8px;

      iframe {
        width: 99.5%;
        height: calc(100% - 70px);
        margin-block-end: 8px;
      }

      .actions {
        display: flex;
        flex-direction: row;
        height: 35px;
        gap: 15px;
        justify-content: center;
        padding-inline: 8px;

        button {
          cursor: pointer;
          border: 0;
          border-radius: 8px;
          padding-inline: 10px;
          background: #dbdbdb;
          font-weight: bold;

          &:hover {
            background: #afafaf;
          }
        }

        .accept {
          background: var(--primary);

          &:hover {
            background: var(--primary-hover);
          }

          &:active {
            background: var(--primary-active);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .concurses-page {
    .concurses {
      flex-direction: column;

      .concurse {
        .actions {
          gap: 25px;
          flex-direction: column;
        }
      }
    }

    .candidates {
      .candidate {
        flex-direction: column;

        .actions {
          justify-content: flex-end;
        }
      }
    }

    .terms {
      .box {
        width: 90%;

        iframe {
          width: 98%;
        }
      }
    }
  }
}
</style>
